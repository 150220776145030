import { Dayjs } from 'dayjs';
import { DateTime, Id } from 'types/common';
import { IExerciseKind } from 'types/entities';

export type CourseId = Id;
export type CourseGroupId = Id;

export interface Exercise {
  id: Id;
  revision: number;
  created: Date;
  createdBy: Record<string, unknown>;
  createdBy_Id: string;
  edited: Date;
  editedBy: Record<string, unknown>;
  editedBy_Id: string;
  label?: string;
  description: string;
  isHiddenDescription: boolean;
  kind: IExerciseKind;
  type: number;
  length: Record<string, unknown>;
  questions: number;
  evaluates: number;
  pages: number;
  hidden: number;
  available: number;
  constraints_Union: number;
  trace: boolean;
  retake: number;
  replay: boolean;
  optional: boolean;
  shuffle: boolean;
  evaluate: boolean;
  scoring: boolean;
  average: boolean;
  feedback: boolean;
  version: number;
  isShowCorrectAnswer: boolean;
  isBivariantEvaluationWholeExercise: boolean;
  isTwoFactorVerificationEnabled: boolean;
  isShowQuestions: boolean;
}

export interface Group {
  training: string;
  label: string;
  lead: {
    id: string;
    initials: string;
  };
}

export interface Instructor {
  // original response
  accountId: string;
  picture: string;
  firstName: string;
  lastName: string;
  eMail: string;
  phone: string;
  workTime: string;
  about: string;
  role: string;
  isHidden: boolean;
}

export interface Tutor {
  id: string;
  surname: string;
  firstname: string;
  patronymic: string;
  initials: string;
}

export interface IDetail {
  lesson: string;
  work: string;
  exercise: string;
  session: string;
  id: string;
  label: string;
  mark: string;
  rawMark: string;
  notes: boolean;
  notesMessage: string;
  files: Array<{ label: string; code: string }>;
}

export enum TRACE_TYPES {
  NOT_DISPLAYED = 0,
  DISABLED = 1,
  NOT_COLLECTED = 2,
  COLLECTING = 3,
  COLLECTED = 4,
  FINISHED
}

export interface IFormattedModuleResult {
  evaluation: boolean;
  value: string;
  rawValue: number;
  timeSpended: string;
}

export interface IFormattedModule {
  id: string;
  label: string;
  description: string;
  isHiddenDescription: boolean;
  lesson: {
    id: string;
    label: string;
    description: string;
    schedule: Date;
    status: 0 | 1 | 2 | 3 | 4 | 5;
    delivering: 0 | 1 | 2;
    lessonBegin?: Dayjs | undefined | null;
    lessonEnd?: Dayjs | undefined | null;
  };
  exercise: {
    id: string;
    label: string;
    retake: number;
    status: 0 | 1 | 2 | 3 | 4 | 5;
    kind: 0 | 10 | 20 | 30;
    isForceRetakeOnlyRejectedResult: boolean;
    isLastResultWasRejected: boolean;
  };
  details?: IDetail[];
  result: IFormattedModuleResult;
  tracing: number;
  trace: TRACE_TYPES;
  status: number;
  files?: Array<{ address: string; title: string }>;
  retakes: number;
  feedback: boolean;
  delivered: boolean;
  notes: string;
  scheduleExercise: Date;
  viewRenderingType: number;
  percents: number;
  available: boolean;
  availableByConstraints: boolean;
  constraints: boolean;
  isScheduleAffectAccess: boolean;
  exerciseBegin?: Dayjs | undefined | null;
  exerciseEnd?: Dayjs | undefined | null;
  location?: string;
}

export interface IDetailedCourseReturnType {
  // original response
  course: string;
  training: string;
  exercise: Exercise;
  picture: string;
  label: string;
  description: string;
  group: Group;
  lead: boolean;
  dates: 0 | 1 | 2 | 3;
  begin: Date;
  end: Date;
  availableTime: number;
  open: Date;
  close: Date;
  trace: boolean;
  age: number;
  support: {
    account: string;
    telegram: string;
    hide_Support: boolean;
    isHideEmailSupport: boolean;
    isHidePhoneNumber: boolean;
    isHideChatSupport: boolean;
    isHideTelegramSupport: boolean;
    telegramAddress: string;
    emailAddress: string;
    initials: string;
    phoneNumber: string;
  };
  instructors: Instructor[];
  modules: IFormattedModule[][];
  tutors: Tutor[];
}

interface IInstructor {
  // transformed response
  id: string;
  picture: string;
  fullName: string;
  email: string;
  phone: string;
  role: string;
}

export interface IModerator {
  hasModerator: boolean;
  id: string;
  initials: string;
}
export interface ISupport {
  initials: string;
  hide: boolean;
  telegram?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
  chat: {
    hide: boolean;
    id: string;
  };
}

export interface IDetailedCourse {
  // transformed response
  courseId: string;
  pictureUrl: string;
  label: string;
  description: string;
  date: {
    status: 0 | 1 | 2 | 3;
    begin: Date;
    end: Date;
  };
  exerciseToContinue: {
    id: string;
    label?: string;
    description: string;
    kind: IExerciseKind;
  } | null;
  groupLabel: string;
  moderator: IModerator;
  instructors: IInstructor[];
  modules: IFormattedModule[][];
  tutor: Tutor;
  support: ISupport;
  trace: boolean;
}

export interface ICourseTag {
  id: string;
  label: string;
}

export interface IResultModule {
  id: string;
  label: string;
  sequence: number;
  notes: boolean;
  mark: string;
}

export type Lesson = {
  id: Id;
  label?: string;
};

//* Lms.Entities.Entities.Training.TrainingStatusValue
// Статусы проведения презентаций
enum CourseStatusEnum {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  PAUSED = 2,
  EVALUATION = 3,
  COMPLETED = 4,
  REVIEW = 5
}

type Skill = string;

export type ViewCourse = {
  id: CourseId;
  lesson: Partial<Lesson>;
  exercise: Pick<Exercise, 'id' | 'label'>; //? Partial
  sequence?: number;
  lessons?: number;
  status?: CourseStatusEnum;
  pages?: number;
  page?: number;
  skills?: Skill[];
  success?: boolean;
  order?: string;
};

export type ViewCourseGroup = {
  id: CourseGroupId;
};

//* Robocode.Areas.Students.ViewModels.Default.NextCourseViewModel.Card_Types
// Типы карточек
export enum CoursesItemTypeEnum {
  COURSE = 1,
  COURSE_GROUP = 2
}

//* Robocode.Areas.Students.ViewModels.Default.NextCourseViewModel
// Курс идущий в данный момент
export type CoursesAndGroupsListItem<T = CoursesItemTypeEnum> = (T extends CoursesItemTypeEnum.COURSE_GROUP
  ? ViewCourseGroup
  : T extends CoursesItemTypeEnum.COURSE
  ? ViewCourse
  : never) & {
  type: T;
  label?: string;
  available: boolean;
  picture?: string;
  group?: string;
  finished: boolean;
  date?: DateTime;
  percents: number;
  notifications: number;
  sort: DateTime;
  keywords?: string;
  begin?: DateTime;
  end?: DateTime;
  expired: boolean;
  isEmptyTraining: boolean;
};
// todo: define, which props has Course and CourseGroup entity

export type UnfinishedCoursesAndGroupsListItem = CoursesAndGroupsListItem;

export type FinishedCoursesAndGroupsListItem = CoursesAndGroupsListItem;

//* Robocode.BLL.DtoModels.Trainings.Students.TrainingsStudentFilter
export enum StudentCourseDateFilterEnum {
  ALL = 0,
  PAST = 1,
  CURRENT = 2,
  FUTURE = 3
}

//* Robocode.Areas.Students.ViewModels.Sets.ModulesAcountCalcViewModel
export interface IModuleProgress {
  id: CourseId | CourseGroupId;
  modulesAmountCalc: number;
  maxModulesAmountCalc?: number;
}

type CoursesAndGroupsListItemWithProgress<T = CoursesItemTypeEnum> = T extends CoursesItemTypeEnum.COURSE_GROUP
  ? CoursesAndGroupsListItem<CoursesItemTypeEnum.COURSE_GROUP> & Pick<IModuleProgress, 'modulesAmountCalc' | 'maxModulesAmountCalc'>
  : T extends CoursesItemTypeEnum.COURSE
  ? CoursesAndGroupsListItem<CoursesItemTypeEnum.COURSE>
  : never;

export type UnfinishedCoursesAndGroupsWithProgressListItem<T = CoursesItemTypeEnum> = CoursesAndGroupsListItemWithProgress<T>;

export type FinishedCoursesAndGroupsWithProgressListItem<T = CoursesItemTypeEnum> = CoursesAndGroupsListItemWithProgress<T>;

export interface ICurrentExercise {
  id: string;
  training?: string;
  lead?: boolean;
  picture?: string;
  exercise: Pick<Exercise, 'id' | 'label'>;
  lesson?: string;
  sequence?: number;
}

export interface ISkills {
  key: string;
  value: string;
}

export interface ISubject {
  id: string;
  label: string;
  select: boolean;
}

export interface ICurrentCourseFolderCard {
  label: string;
  description?: string;
  skills: ISkills[];
  isActualList: boolean;
  subjects: ISubject[];
  picture?: string;
}

export interface ICurrentCourseFolder extends ICurrentCourseFolderCard {
  trainings: CoursesAndGroupsListItem<CoursesItemTypeEnum.COURSE>[];
  tutor: Tutor;
}

/* 
todo: навести порядок в original и transformed responses
 */
