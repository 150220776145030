import { CSSProperties } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { IVariation } from 'types/common';

import { StoryObj } from '@storybook/react';

type IComponentStatesTableProps = {
  title?: string;
  component: React.ComponentType | React.ElementType;
  parameters: StoryObj<IComponentStatesTableProps['component']>['parameters'];
  variations: IVariation<IComponentStatesTableProps['component']>[];
  defaultProps?: React.ComponentProps<IComponentStatesTableProps['component']>;
  ghost?: boolean;
  style?: CSSProperties;
};

type IStates = Array<{
  name: string;
  id?: string;
}>;

export const ComponentStatesTable = ({
  title,
  component: Component,
  parameters,
  variations,
  defaultProps,
  ghost = false,
  style
}: IComponentStatesTableProps) => {
  const states: IStates = [
    { name: 'Исходное' },
    ...Object.entries(parameters?.pseudo as Record<string, string[]>).map(([name, ids]) => {
      const id = ids[0].charAt(0) === '#' ? ids[0].substring(1) : undefined;
      return { name, id };
    }),
    { name: 'disabled' },
    { name: 'readOnly' }
  ];

  return (
    <table
      style={{
        minWidth: '100%',
        borderCollapse: 'collapse',
        marginTop: '1rem',
        backgroundColor: ghost ? 'hsl(120, 60%, 10%)' : 'hsl(120, 60%, 95%)',
        ...style
      }}
    >
      {/* @ts-ignore */}
      <caption style={{ fontWeight: 'bold' }}>{title ?? Component.displayName}</caption>
      <thead>
        <tr>
          <th style={{ width: '100px', backgroundColor: 'hsl(120, 60%, 70%)' }}>Props</th>
          {states.map((state, i) => (
            <th key={i} align='center' style={{ backgroundColor: 'hsl(120, 50%, 75%)' }}>
              {state.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[{ name: 'По умолчанию', props: defaultProps }, ...variations].map((variation, i) => (
          <tr key={i}>
            <td style={{ border: '1px solid hsl(0, 0%, 50%)', padding: '5px', backgroundColor: 'hsl(120, 60%, 70%)' }}>{variation.name}</td>
            {states.map((state, i) => {
              const stateProps = {
                id: state.id,
                disabled: state.name === 'disabled',
                readOnly: state.name === 'readOnly'
              };

              return (
                <td key={i} align='center' style={{ border: '1px solid hsl(0, 0%, 50%)', padding: '5px' }}>
                  <Component {...parameters} {...variation.props} {...stateProps} />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

type IComponentSizesTableProps = {
  title?: string;
  component: React.ComponentType | React.ElementType;
  sizes: SizeType[];
  variations: IVariation<IComponentSizesTableProps['component']>[];
};

export const ComponentSizesTable = ({ title, component: Component, sizes, variations }: IComponentSizesTableProps) => {
  return (
    <table
      style={{
        minWidth: '100%',
        borderCollapse: 'collapse',
        marginTop: '1rem',
        backgroundColor: 'hsl(120, 60%, 95%)'
      }}
    >
      {/* @ts-ignore */}
      <caption style={{ fontWeight: 'bold' }}>{title ?? Component.displayName}</caption>
      <thead>
        <tr>
          <th style={{ width: '100px', backgroundColor: 'hsl(120, 60%, 70%)' }}>Props</th>
          {sizes.map((size, i) => (
            <th key={i} align='center' style={{ backgroundColor: 'hsl(120, 50%, 75%)' }}>
              {size}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[{ name: 'По умолчанию' }, ...variations].map((variation, i) => (
          <tr key={i}>
            <td style={{ border: '1px solid hsl(0, 0%, 50%)', padding: '5px', backgroundColor: 'hsl(120, 60%, 70%)' }}>{variation.name}</td>
            {sizes.map((size, i) => {
              return (
                <td key={i} align='center' style={{ border: '1px solid hsl(0, 0%, 50%)', padding: '5px', verticalAlign: 'top' }}>
                  <Component size={size} {...variation.props} />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
