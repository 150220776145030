export const instructorLabels = {
  programs: 'Программы',
  programView: 'Программа',
  programCreate: 'Создать программу',
  programEdit: 'Редактировать программу',
  programSort: 'Сортировка уроков программы',
  programAuthors: 'Авторы программы',
  lessonCreate: 'Создать урок программы',
  lessonEdit: 'Редактировать урок программы',
  lessonConstraints: 'Ограничения урока программы',
  exerciseCreate: 'Создать упражнение',
  exerciseEdit: 'Редактировать упражнение',
  scormCreate: 'Создать SCORM',
  exerciseConstraints: 'Ограничения упражнения программы',
  exerciseCompetencies: 'Компетенции упражнения программы',
  exerciseFiles: 'Файлы упражнения программы',
  exerciseSlides: 'Слайды упражнения',
  exerciseSlidesCreate: 'Создание слайда упражнения',
  exerciseSlidesEdit: 'Редактирование слайда упражнения',
  news: 'Новости',
  newsCreate: 'Создать новость',
  newsEdit: 'Редактировать новость',
  courseGroup: 'Группа курсов',
  course: 'Курс',
  courseAutoNotifications: 'События рассылки',
  courseAutoNotificationCreate: 'Рассылка сообщения по электронной почте',
  courseAutoNotificationEdit: 'Рассылка сообщения по электронной почте',
  courseManualNotifications: 'Рассылки',
  courseManualNotification: 'Просмотр рассылки',
  courseManualNotificationCreate: 'Рассылка сообщения по электронной почте',
  courseManualNotificationSend: 'Рассылка сообщения по электронной почте',
  courseManualNotificationEdit: 'Рассылка сообщения по электронной почте',
  courses: 'Курсы',
  coursesUsers: 'Поиск пользователей',
  coursesList: 'Просмотр курсов',
  courseInstructors: 'Преподаватели',
  courseInstructorsEdit: 'Роль в обучении',
  courseInstructorsCreate: 'Включение преподавателя в обучение',
  studentsJournal: 'Журнал учащихся',
  studentsJournalDownload: 'Выгрузка журнала учащихся',
  visitJournal: 'Журнал посещений',
  visitJournalDownload: 'Выгрузка журнала посещений',
  resources: 'Ресурсы',
  closedQuestions: 'Вопросы с вариантами ответов',
  closedQuestionCreate: 'Создание вопроса с вариантами ответов',
  closedQuestionEdit: 'Редактирование вопроса с вариантами ответов',
  freeQuestions: 'Вопросы со свободным ответом',
  freeQuestionCreate: 'Создание вопроса со свободным ответом',
  freeQuestionEdit: 'Редактирование вопроса со свободным ответом',
  links: 'Артефакты (ссылки)',
  templates: 'Настройка шаблонов заявок на отчисление',
  programCopy: 'Копирование уроков, упражнений',
  profileRights: 'Права',
  thesaurus: 'Тезаурус',
  courseCreate: 'Создание курса',
  coursesGroupCreate: 'Создание группы курсов',
  coursesGroupEdit: 'Редактирование группы курсов',
  coursesGroupInstructorsAdd: 'Включение преподавателей по иерархии',
  coursesGroupInstructorsRemove: 'Удаление преподавателей по иерархии',
  coursesStudentManagement: 'Управление студентами',
  studentsJournalCheck: 'Упражнения для проверки',
  coursesEdit: 'Редактирование курса',
  auditJournal: 'Журнал аудита',
  coursesStudentsChanges: 'История изменения профилей',
  coursesStudentsGroups: 'Группы обучения',
  coursesStudentsImport: 'Импорт данных',
  courseResponsesUpload: 'Выгрузка ответов для программ',
  lessonResponsesUpload: 'Выгрузка ответов для уроков',
  exerciseResponsesUpload: 'Выгрузка ответов для упражнений',
  exportToCRMTaskQueue: 'Очередь задач',
  instructorEstimatesExportTaskQueue: 'Очередь задач',
  visitJournalExportTaskQueue: 'Очередь задач',
  studentsJournalExportTaskQueue: 'Очередь задач',
  coursesGroupInstructorsRemoveTaskQueue: 'Очередь задач',
  courseLinks: 'Ссылки',
  certificates: 'Удостоверения',
  certificateCreate: 'Загрузка удостоверения',
  expulsion: 'Заявки на отчисление',
  enrollment: 'Заявки на зачисление'
};
