import { Id, PaginationApiMetadata } from 'types/common';
import { AccountId, ExerciseId, LessonId } from 'types/entities';
import { IExercises } from './';

type CourseAge = 0 | 1 | 2 | 3 | 4;

export const COURSE_AGES = ['Любой', 'Дети', 'Школьники', 'Студенты', 'Взрослые'];

// Объект списка курсов для инструктора
export interface ICourse {
  id: Id;
  label: string;
  picture: string;
  age: CourseAge;
  ready: boolean;
}

//* Robocode.Models.Instructors.Courses.View.Lesson
export type LessonView = {
  id: LessonId;
  label?: string;
  description?: string;
  constraints: boolean;
  n: number;
};

interface IAuthor {
  id: Id;
  picture: string;
  first: string;
  last: string;
  eMail: string;
  phone: string;
}

// Объект курса для инструктора
export interface ICourseById {
  id: Id;
  label: string;
  description: string;
  file: string;
  icon: string;
  picture: string;
  pictureId: string;
  trace: boolean;
  age: CourseAge;
  lessons: LessonView[];
  lectures: number;
  tasks: number;
  tests: number;
  authors: IAuthor[];
  min: number;
  max: number;
  done: boolean;
}

// Тело объетка курса
export interface ICourseBody {
  id: Id;
  label: string;
  description: string;
  pictureId?: string;
  age: CourseAge;
  done: boolean;
}

// Обьект Групп обучения
export interface ITrainingGroup {
  id: string;
  label: string;
  title: string;
}

//* CQRS.Models.ExerciseInfoResult
// todo: update
export type ExerciseInfo = {
  labelExercise: string;
  isDisabled: boolean; //индикация параметра активности упражнения по фильтр-статусу
  index: number;
  percents: number;
  hasFiles: boolean;
  available: boolean;
  done: boolean;
  status: number;
  averageMark: number;
  fileInfo: {
    exercise: ExerciseId;
    student: AccountId;
  };
};

export interface IJournalTable {
  data: IJournal[];
  filterMetadataStudent: PaginationApiMetadata;
}

export interface IJournal {
  studentId: string;
  initials: string;
  groupName?: string;
  email: string;
  done: number;
  evaluated?: number;
  exercises: ExerciseInfo[];
  duration?: number;
  result?: number;
}

export interface IVisitJournalTable {
  accountId: string;
  email: string;
  initials: string;
  isHasVisit: boolean;
}

export interface IVisitJournalTableData {
  data: IVisitJournalTable[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export interface IVisitJournalLesson {
  lessonId: string;
  lessonName: string;
  sequence: number;
}

export interface IVisitJournalExercise {
  exerciseId: string;
  exerciseName: string;
}

export enum ICourseStudyStatusesEnum {
  Study = 1,
  Expelled = 2,
  Dropped = 3
}

export type ILessonsExercises = { label?: string; id?: LessonId; code?: string; nodes?: IExercises[] };
