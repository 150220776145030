import { lazy } from 'react';
import type { IRoute } from 'types/common';
import { ROLES } from 'types/roles';
import { instructorLabels as labels, instructorPaths as paths } from 'types/routes/instructor';

// Programs
const Programs = lazy(() => import('pages/instructor/[Programs]/Programs'));

const ProgramView = lazy(() => import('pages/instructor/[Programs]/[Program]/ProgramView'));
const ProgramCreate = lazy(() => import('pages/instructor/[Programs]/[Program]/ProgramCreate'));
const ProgramEdit = lazy(() => import('pages/instructor/[Programs]/[Program]/ProgramEdit'));
const ProgramAuthors = lazy(() => import('pages/instructor/[Programs]/[Program]/ProgramAuthors'));
const ProgramSort = lazy(() => import('pages/instructor/[Programs]/[Program]/ProgramSort'));
const ProgramCopy = lazy(() => import('pages/instructor/[Programs]/[Program]/ProgramCopy'));

const LessonCreate = lazy(() => import('pages/instructor/[Programs]/[Program]/[Lesson]/LessonCreate'));
const LessonEdit = lazy(() => import('pages/instructor/[Programs]/[Program]/[Lesson]/LessonEdit'));
const LessonConstraints = lazy(() => import('pages/instructor/[Programs]/[Program]/[Lesson]/LessonConstraints'));

const ExerciseCreate = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/ExerciseCreate'));
const ExerciseEdit = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/ExerciseEdit'));
const ExerciseScormCreate = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/ExerciseScormCreate'));
const ExerciseConstraints = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/ExerciseConstraints'));
const ExerciseCompetencies = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/ExerciseCompetencies'));
const ExerciseFiles = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/ExerciseFiles'));

const ExerciseSlides = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/[Slides]/ExerciseSlides'));
const ExerciseSlideCreate = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/[Slides]/ExerciseSlideCreate'));
const ExerciseSlideEdit = lazy(() => import('pages/instructor/[Programs]/[Program]/[Exercise]/[Slides]/ExerciseSlideEdit'));

// News
const News = lazy(() => import('pages/instructor/[News]/News'));
const NewsCreate = lazy(() => import('pages/instructor/[News]/NewsCreate'));
const NewsEdit = lazy(() => import('pages/instructor/[News]/NewsEdit'));

// Courses
const Courses = lazy(() => import('pages/instructor/[Courses]/Courses'));
const CoursesTable = lazy(() => import('pages/instructor/[Courses]/CoursesTable'));
const CoursesAuditJournal = lazy(() => import('pages/instructor/[Courses]/CoursesAuditJournal'));

const CoursesUsers = lazy(() => import('pages/instructor/[Courses]/[CoursesUsers]/CoursesUsers'));
const ProfileRights = lazy(() => import('pages/instructor/[Courses]/[CoursesUsers]/ProfileRights'));

const Course = lazy(() => import('pages/instructor/[Courses]/[Course]/CourseView'));
const CourseCreate = lazy(() => import('pages/instructor/[Courses]/[Course]/CourseCreate'));
const CourseEdit = lazy(() => import('pages/instructor/[Courses]/[Course]/CourseEdit'));

const CourseInstructors = lazy(() => import('pages/instructor/[Courses]/[Course]/[Instructors]/CourseInstructorsView'));
const CourseInstructorsEdit = lazy(() => import('pages/instructor/[Courses]/[Course]/[Instructors]/CourseInstructorsEdit'));
const CourseInstructorsCreate = lazy(() => import('pages/instructor/[Courses]/[Course]/[Instructors]/CourseInstructorsCreate'));

const StudentsJournal = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsJournal]/StudentsJournal'));
const StudentsJournalCheck = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsJournal]/StudentsJournalCheck'));
const StudentsJournalDownload = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsJournal]/StudentsJournalDownload'));

const CoursesStudentManagement = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsManagement]/StudentsManagement'));
const CoursesStudentsChanges = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsManagement]/Changes'));
const CoursesStudentsGroups = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsManagement]/Groups'));
const CoursesStudentsImport = lazy(() => import('pages/instructor/[Courses]/[Course]/[StudentsManagement]/Import'));

const VisitJournal = lazy(() => import('pages/instructor/[Courses]/[Course]/[VisitJournal]/VisitJournal'));
const VisitJournalDownload = lazy(() => import('pages/instructor/[Courses]/[Course]/[VisitJournal]/VisitJournal/VisitJournalDownload'));

const CourseAutoNotifications = lazy(() => import('pages/instructor/[Courses]/[Course]/[Notifications]/AutoNotifications'));
const CourseAutoNotificationCreate = lazy(
  () => import('pages/instructor/[Courses]/[Course]/[Notifications]/AutoNotifications/AutoNotificationCreate')
);
const CourseAutoNotificationsEdit = lazy(
  () => import('pages/instructor/[Courses]/[Course]/[Notifications]/AutoNotifications/AutoNotificationEdit')
);
const CourseManualNotifications = lazy(() => import('pages/instructor/[Courses]/[Course]/[Notifications]/ManualNotifications'));
const CourseManualNotification = lazy(
  () => import('pages/instructor/[Courses]/[Course]/[Notifications]/ManualNotifications/ManualNotification')
);
const CourseManualNotificationCreate = lazy(
  () => import('pages/instructor/[Courses]/[Course]/[Notifications]/ManualNotifications/ManualNotificationCreate')
);
const CourseManualNotificationEdit = lazy(
  () => import('pages/instructor/[Courses]/[Course]/[Notifications]/ManualNotifications/ManualNotificationEdit')
);
const CourseManualNotificationSend = lazy(
  () => import('pages/instructor/[Courses]/[Course]/[Notifications]/ManualNotifications/ManualNotificationSend')
);

const CourseGroup = lazy(() => import('pages/instructor/[Courses]/[CourseGroup]/CourseGroup'));
const CourseGroupCreate = lazy(() => import('pages/instructor/[Courses]/[CourseGroup]/CourseGroupCreate'));
const CourseGroupEdit = lazy(() => import('pages/instructor/[Courses]/[CourseGroup]/CourseGroupEdit'));
const InstructorsAdd = lazy(() => import('pages/instructor/[Courses]/[CourseGroup]/CourseGroup/InstructorsAdd'));
const InstructorsRemove = lazy(() => import('pages/instructor/[Courses]/[CourseGroup]/CourseGroup/InstructorsRemove'));

const CourseResponsesUpload = lazy(() => import('pages/instructor/[Courses]/[Course]/[ResponsesUpload]/CourseResponsesUpload'));
const ExerciseResponsesUpload = lazy(() => import('pages/instructor/[Courses]/[Course]/[ResponsesUpload]/ExerciseResponsesUpload'));
const LessonResponsesUpload = lazy(() => import('pages/instructor/[Courses]/[Course]/[ResponsesUpload]/LessonResponsesUpload'));

const CourseLinks = lazy(() => import('pages/instructor/[Courses]/[Course]/CourseLinks'));

const Certificates = lazy(() => import('pages/instructor/[Courses]/[Course]/[Certificates]'));
const CertificateCreate = lazy(() => import('pages/instructor/[Courses]/[Course]/[Certificates]/CertificateCreate'));
// Resources
const ClosedQuestions = lazy(() => import('pages/instructor/[Resources]/[ClosedQuestions]/ClosedQuestions'));
const ClosedQuestionCreate = lazy(() => import('pages/instructor/[Resources]/[ClosedQuestions]/ClosedQuestionCreate'));
const ClosedQuestionEdit = lazy(() => import('pages/instructor/[Resources]/[ClosedQuestions]/ClosedQuestionEdit'));

const FreeQuestions = lazy(() => import('pages/instructor/[Resources]/[FreeQuestions]/FreeQuestions'));
const FreeQuestionCreate = lazy(() => import('pages/instructor/[Resources]/[FreeQuestions]/FreeQuestionCreate'));
const FreeQuestionEdit = lazy(() => import('pages/instructor/[Resources]/[FreeQuestions]/FreeQuestionEdit'));

const Links = lazy(() => import('pages/instructor/[Resources]/[Links]/Links'));

const Templates = lazy(() => import('pages/instructor/[Resources]/[Templates]/Templates'));

// Thesaurus
const Thesaurus = lazy(() => import('pages/instructor/[Thesaurus]/Thesaurus'));

// TaskQueue
const TaskQueue = lazy(() => import('pages/instructor/TaskQueue'));

// Applications
const Expulsion = lazy(() => import('pages/instructor/Expulsion'));
const Enrollment = lazy(() => import('pages/instructor/Enrollment'));

const INSTRUCTOR_WITH_NEWS_PRESET: typeof ROLES.INSTRUCTOR = {
  role: ROLES.INSTRUCTOR.role,
  permissions: {
    ...ROLES.INSTRUCTOR.permissions,
    NewsFeedSettings: true
  }
};
const INSTRUCTOR_WITH_ADMINPANEL_PRESET: typeof ROLES.INSTRUCTOR = {
  role: ROLES.INSTRUCTOR.role,
  permissions: {
    ...ROLES.INSTRUCTOR.permissions,
    AdminPanelAccess: true
  }
};

export const instructorRoutes: IRoute[] = [
  {
    path: paths.programs,
    label: labels.programs,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Programs />
  },
  {
    path: paths.programView,
    label: labels.programView,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProgramView />
  },
  {
    path: paths.programCreate,
    label: labels.programCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProgramCreate />
  },
  {
    path: paths.programEdit,
    label: labels.programEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProgramEdit />
  },
  {
    path: paths.programSort,
    label: labels.programSort,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProgramSort />
  },
  {
    path: paths.programAuthors,
    label: labels.programAuthors,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProgramAuthors />
  },
  {
    path: paths.lessonCreate,
    label: labels.lessonCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <LessonCreate />
  },
  {
    path: paths.lessonEdit,
    label: labels.lessonEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <LessonEdit />
  },
  {
    path: paths.lessonConstraints,
    label: labels.lessonConstraints,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <LessonConstraints />
  },
  {
    path: paths.exerciseCreate,
    label: labels.exerciseCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseCreate />
  },
  {
    path: paths.exerciseEdit,
    label: labels.exerciseEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseEdit />
  },
  {
    path: paths.scormCreate,
    label: labels.scormCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseScormCreate />
  },
  {
    path: paths.exerciseConstraints,
    label: labels.exerciseConstraints,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseConstraints />
  },
  {
    path: paths.exerciseCompetencies,
    label: labels.exerciseCompetencies,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseCompetencies />
  },
  {
    path: paths.exerciseFiles,
    label: labels.exerciseFiles,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseFiles />
  },
  {
    path: paths.news,
    label: labels.news,
    roles: [ROLES.ADMIN, INSTRUCTOR_WITH_NEWS_PRESET],
    element: <News />
  },
  {
    path: paths.newsCreate,
    label: labels.newsCreate,
    roles: [ROLES.ADMIN, INSTRUCTOR_WITH_NEWS_PRESET],
    element: <NewsCreate />
  },
  {
    path: paths.newsEdit,
    label: labels.newsEdit,
    roles: [ROLES.ADMIN, INSTRUCTOR_WITH_NEWS_PRESET],
    element: <NewsEdit />
  },
  {
    path: paths.exerciseSlides,
    label: labels.exerciseSlides,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseSlides />
  },
  {
    path: paths.exerciseSlidesCreate,
    label: labels.exerciseSlidesCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseSlideCreate />
  },
  {
    path: paths.exerciseSlidesEdit,
    label: labels.exerciseSlidesEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseSlideEdit />
  },
  {
    path: paths.courses,
    label: labels.courses,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Courses />
  },
  {
    path: paths.coursesUsers,
    label: labels.coursesUsers,
    roles: [ROLES.ADMIN, INSTRUCTOR_WITH_ADMINPANEL_PRESET],
    element: <CoursesUsers />
  },
  {
    path: paths.coursesList,
    label: labels.coursesList,
    roles: [ROLES.ADMIN, INSTRUCTOR_WITH_ADMINPANEL_PRESET],
    element: <CoursesTable />
  },
  {
    path: paths.courseInstructors,
    label: labels.courseInstructors,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseInstructors />
  },
  {
    path: paths.courseInstructorsEdit,
    label: labels.courseInstructorsEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseInstructorsEdit />
  },
  {
    path: paths.courseInstructorsCreate,
    label: labels.courseInstructorsCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseInstructorsCreate />
  },
  {
    path: paths.studentsJournal,
    label: labels.studentsJournal,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <StudentsJournal />
  },
  {
    path: paths.studentsJournalDownload,
    label: labels.studentsJournalDownload,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <StudentsJournalDownload />
  },
  {
    path: paths.visitJournal,
    label: labels.visitJournal,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <VisitJournal />
  },
  {
    path: paths.visitJournalDownload,
    label: labels.visitJournalDownload,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <VisitJournalDownload />
  },
  {
    path: paths.resources,
    label: labels.resources,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ClosedQuestions />
  },
  {
    path: paths.closedQuestions,
    label: labels.closedQuestions,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ClosedQuestions />
  },
  {
    path: paths.closedQuestionCreate,
    label: labels.closedQuestionCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ClosedQuestionCreate />
  },
  {
    path: paths.closedQuestionEdit,
    label: labels.closedQuestionEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ClosedQuestionEdit />
  },
  {
    path: paths.freeQuestions,
    label: labels.freeQuestions,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <FreeQuestions />
  },
  {
    path: paths.freeQuestionCreate,
    label: labels.freeQuestionCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <FreeQuestionCreate />
  },
  {
    path: paths.freeQuestionEdit,
    label: labels.freeQuestionEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <FreeQuestionEdit />
  },
  {
    path: paths.links,
    label: labels.links,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Links />
  },
  {
    path: paths.templates,
    label: labels.templates,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Templates />
  },
  {
    path: paths.courseGroup,
    label: labels.courseGroup,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseGroup />
  },
  {
    path: paths.programCopy,
    label: labels.programCopy,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProgramCopy />
  },
  {
    path: paths.profileRights,
    label: labels.profileRights,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ProfileRights />
  },
  {
    path: paths.course,
    label: labels.course,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Course />
  },
  {
    path: paths.courseAutoNotifications,
    label: labels.courseAutoNotifications,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseAutoNotifications />
  },
  {
    path: paths.courseAutoNotificationCreate,
    label: labels.courseAutoNotifications,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseAutoNotificationCreate />
  },
  {
    path: paths.courseAutoNotificationEdit,
    label: labels.courseAutoNotificationEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseAutoNotificationsEdit />
  },
  {
    path: paths.courseManualNotifications,
    label: labels.courseManualNotifications,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseManualNotifications />
  },
  {
    path: paths.courseManualNotification,
    label: labels.courseManualNotification,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseManualNotification />
  },
  {
    path: paths.courseManualNotificationCreate,
    label: labels.courseManualNotificationCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseManualNotificationCreate />
  },
  {
    path: paths.courseManualNotificationEdit,
    label: labels.courseManualNotificationEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseManualNotificationEdit />
  },
  {
    path: paths.courseManualNotificationSend,
    label: labels.courseManualNotificationSend,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseManualNotificationSend />
  },
  {
    path: paths.thesaurus,
    label: labels.thesaurus,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Thesaurus />
  },
  {
    path: paths.courseCreate,
    label: labels.courseCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseCreate />
  },
  {
    path: paths.coursesGroupCreate,
    label: labels.coursesGroupCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseGroupCreate />
  },
  {
    path: paths.coursesGroupEdit,
    label: labels.coursesGroupEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseGroupEdit />
  },
  {
    path: paths.coursesGroupInstructorsAdd,
    label: labels.coursesGroupInstructorsAdd,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <InstructorsAdd />
  },
  {
    path: paths.coursesGroupInstructorsRemove,
    label: labels.coursesGroupInstructorsRemove,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <InstructorsRemove />
  },
  {
    path: paths.coursesStudentManagement,
    label: labels.coursesStudentManagement,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CoursesStudentManagement />
  },
  {
    path: paths.studentsJournalCheck,
    label: labels.studentsJournalCheck,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <StudentsJournalCheck />
  },
  {
    path: paths.coursesEdit,
    label: labels.coursesEdit,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseEdit />
  },
  {
    path: paths.auditJournal,
    label: labels.auditJournal,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CoursesAuditJournal />
  },
  {
    path: paths.coursesStudentsChanges,
    label: labels.coursesStudentsChanges,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CoursesStudentsChanges />
  },
  {
    path: paths.coursesStudentsGroups,
    label: labels.coursesStudentsGroups,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CoursesStudentsGroups />
  },
  {
    path: paths.coursesStudentsImport,
    label: labels.coursesStudentsImport,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CoursesStudentsImport />
  },
  {
    path: paths.courseResponsesUpload,
    label: labels.courseResponsesUpload,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseResponsesUpload />
  },
  {
    path: paths.lessonResponsesUpload,
    label: labels.lessonResponsesUpload,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <LessonResponsesUpload />
  },
  {
    path: paths.exerciseResponsesUpload,
    label: labels.exerciseResponsesUpload,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <ExerciseResponsesUpload />
  },
  /**
   * TaskQueue - специфическая страница, которая открывается после различных действий в различных местах системы.
   * Поэтому для одной страницы есть несколько путей, по которым к ней можно прийти.
   * ToDo: прописать все варианты, по которым можно добраться до TaskQueue.
   */
  {
    path: paths.visitJournalExportTaskQueue,
    label: labels.visitJournalExportTaskQueue,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <TaskQueue />
  },
  {
    path: paths.studentsJournalExportTaskQueue,
    label: labels.studentsJournalExportTaskQueue,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <TaskQueue />
  },
  {
    path: paths.exportToCRMTaskQueue,
    label: labels.exportToCRMTaskQueue,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <TaskQueue />
  },
  {
    path: paths.instructorEstimatesExportTaskQueue,
    label: labels.instructorEstimatesExportTaskQueue,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <TaskQueue />
  },
  {
    path: paths.coursesGroupInstructorsRemoveTaskQueue,
    label: labels.coursesGroupInstructorsRemoveTaskQueue,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <TaskQueue />
  },
  {
    path: paths.courseLinks,
    label: labels.courseLinks,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CourseLinks />
  },
  {
    path: paths.certificates,
    label: labels.certificates,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Certificates />
  },
  {
    path: paths.certificateCreate,
    label: labels.certificateCreate,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <CertificateCreate />
  },
  {
    path: paths.expulsion,
    label: labels.expulsion,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Expulsion />
  },
  {
    path: paths.enrollment,
    label: labels.enrollment,
    roles: [ROLES.ADMIN, ROLES.INSTRUCTOR],
    element: <Enrollment />
  }
];
