import { FC, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { notification } from 'antd';
import { ErrorBoundary } from 'containers';
import { BackToTopButton, Header, Loader } from 'common/components';
import { checkError } from 'common/helpers';
import { useAppSelector, useRedirect } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { authErrorSelectors } from 'api/guest/authError/auth-error.selector';
import { oneIdSelectors } from 'api/guest/oneId/one-id.selector';
import { OneIdAuthError } from 'types/store/guest/one-id.slice';

const OneIdError = ({ error }: { error: OneIdAuthError }) => {
  throw new Error(error.Description);
};

export const Root: FC = () => {
  const { accountId: isLogged } = useAppSelector(profileSelectors.profile);
  const { authError } = useAppSelector(authErrorSelectors.value);
  const oneId = useAppSelector(oneIdSelectors.value);

  const redirect = useRedirect();

  useEffect(() => {
    const error = authError;
    if (checkError({ error, status: 555 })) {
      redirect.authLoader();
      return;
    }
    const { title = '', description = '' } =
      !!error && 'status' in error ? ((error?.data ?? {}) as { title?: string; description?: string }) : {};
    if (checkError({ error, status: 401 })) {
      if (title && description) {
        notification.error({ message: title, description });
        return;
      }

      redirect.welcome();
      return;
    }
  }, [authError, redirect]);

  return (
    <>
      {isLogged && <Header />}
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>{oneId.error ? <OneIdError error={oneId.error} /> : <Outlet />}</Suspense>
        <BackToTopButton />
      </ErrorBoundary>
    </>
  );
};
