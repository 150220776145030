import { Id, ISlideLabels } from 'types/common';

export type EvaluateTypeText = string;

export enum EvaluateTypeEnum {
  NO_RATING,
  AUTO_CHECK,
  MANUAL_CHECK
}
export enum QuestionTypeEnum {
  UNKNOWN = 0,
  BOARD_WITH_BLOCKS_AND_COMMANDS = 1,
  CLOSED_QUESTION = 2,
  QUESTION_WITH_CODE = 3,
  QUESTION_WITH_HTML = 4,
  FREE_RESPONSE = 5,
  DRAWING = 6,
  DOCUMENT = 7,
  GRADED_ACTIVITY = 8,
  ATTRIBUTES_TO_FILL = 9,
  SCORM_SLIDE = 10,
  EXTERNAL_QUESTION = 101
}

// Объект получения страниц для упражнения.
export interface IExerciseSlide {
  id: Id;
  sequence: number;
  label: string;
  type: ISlideLabels;
  question: boolean;
  skills: boolean;
  hide: boolean;
  isHiddenAsResourse: boolean;
  evaluateType: EvaluateTypeEnum;
  supportedEvaluateTypes: { evaluateType: EvaluateTypeEnum; evaluateTypeText: EvaluateTypeText }[];
  questionType: QuestionTypeEnum;
}
