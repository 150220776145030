import { FC } from 'react';
import { Flex } from 'antd';
import { Icon, Link } from 'common/ui/_new';
import styles from './styles.module.scss';

export const SupportContacts: FC = () => {
  return (
    <Flex vertical gap={16} align='flex-start'>
      <Link className={styles.link} icon={<Icon type='sm-telegram' className={styles.icon} />} to='https://t.me/learniu_bot'>
        https://t.me/learniu_bot
      </Link>
      <Link className={styles.link} icon={<Icon type='mail' className={styles.icon} />} to='mailto:lms.support@innopolis.ru'>
        lms.support@innopolis.ru
      </Link>
      <Link className={styles.link} icon={<Icon type='phone' className={styles.icon} />} to='tel:+78005503171'>
        8 800 550 31 71
      </Link>
    </Flex>
  );
};
