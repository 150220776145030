import { PresetStatusColorType } from 'antd/es/_util/colors';
import { CourseId, ExerciseId } from 'types/entities';

export type ExerciseLocation = string;

export enum ExerciseMediaTypeEnum {
  Unknown,
  Presentation,
  SCORM
}

export enum VerificationStatusEnum {
  Verified = 1,
  NotRequireVerification,
  VerificationRequires
}

export enum ExerciseOptionsTypeEnum {
  Any = 0,
  Lecture = 10,
  Testing = 20,
  Independent = 30
}

export enum ExerciseTypeEnum {
  NotAvailable = 1,
  NotStarted,
  Inprogress,
  noVerificationRequired,
  VerificationRequired,
  Verified
}

export const enum ExerciseAvailabilityEnum {
  Unavailable,
  AfterPrevious,
  ImmediatelyInWork,
  ImmediatelyInLesson,
  Always = 99
}

export const AVAILABILITY_OPTIONS: Array<{
  value: ExerciseAvailabilityEnum;
  label: string;
  badgeStatus: PresetStatusColorType;
}> = [
  { value: ExerciseAvailabilityEnum.Unavailable, label: 'Недоступен', badgeStatus: 'error' },
  { value: ExerciseAvailabilityEnum.AfterPrevious, label: 'Доступен после предыдущего упражнения', badgeStatus: 'warning' },
  { value: ExerciseAvailabilityEnum.ImmediatelyInWork, label: 'Доступен сразу в своей работе', badgeStatus: 'warning' },
  { value: ExerciseAvailabilityEnum.ImmediatelyInLesson, label: 'Доступен сразу в своём уроке', badgeStatus: 'warning' },
  { value: ExerciseAvailabilityEnum.Always, label: 'Доступен всегда', badgeStatus: 'success' }
];

export const WORK_OPTIONS = [
  { value: ExerciseOptionsTypeEnum.Lecture, label: 'Лекция' },
  { value: ExerciseOptionsTypeEnum.Testing, label: 'Тестирование' },
  { value: ExerciseOptionsTypeEnum.Independent, label: 'Самостоятельная работа' }
];

//* Interfaces.DtoModels.ExercisePresentationEditDto
// todo: update
export interface IExercise {
  exerciseId: ExerciseId;
  label: string;
  description: string;
  isHiddenDescription: boolean;
  available: number;
  retake: boolean;
  retakes: number;
  isForceRetakeOnlyRejectedResult: boolean;
  optional: boolean;
  shuffle: boolean;
  evaluate: boolean;
  work: ExerciseOptionsTypeEnum;
  scoring: boolean;
  average: boolean;
  feedback: boolean;
  isShowCorrectAnswer: boolean;
  isBivariantEvaluationWholeExercise: boolean;
  isTwoFactorVerificationExercise: boolean;
  isShowQuestions: boolean;
  replay: boolean;
  isScoExercise: boolean;
  scoreThreshold?: number;
}

export interface IExerciseAttribute {
  data: {
    type: number;
  };
  id: string;
  label: string;
}

export interface IExerciseAttributeValue {
  id: string;
  label: string;
}

export interface ICreateConstraints {
  exerciseId: string;
  attributeId: string;
  valueIds: string[];
}
export interface IExerciseLocation {
  exerciseLabel: string;
  address: string;
}

export type ModuleNum = string;
export type ModuleId = string;

export interface ExerciseTrainingTraceAdditionalFieldsData {
  trainingId: CourseId;
  exerciseId: ExerciseId;
  moduleNum?: ModuleNum;
  moduleId?: ModuleId;
}
